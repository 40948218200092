:root {
  --primary: #376618;
  --secondary: #9B9B9B;
  --light: #F5F5F5;
  --dark: #161616;
}
small{
  font-weight: bold;
}
.container-xxl .card1{
  background-image: url(/public/Images/Alkhair.png);
  background-image: linear-gradient(rgb(44, 107, 49), rgb(155, 155, 51));
}
.container-xxl .card{
  background-color: rgb(228, 228, 223);
}
 .container h1{
  font-weight: 1000;
  font-size: 50px;
 }
 .ms-4 p{
  font-weight: bolder;
  font-style: inherit;
 }
 .ms-4 h5{
  font-size: small;
  font-style: italic;
 }
 .mb-4{
  font-style: italic;

 }
 .navbar{
  position: fixed;
 }

.fw-medium {
  font-weight: 500 !important;
}
.fw-bold {
  font-weight: 700 !important;
}
.fw-black {
  font-weight: 900 !important;
}
.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}
/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}
.btn.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.navbar-nav i{
  margin-right: 15px;

}
.navbar-brand img{
  width: 60px;
  margin-right: 20px;
  margin-bottom: 10px;
}
.custom-bg{
  background-color: #2863A4;
}
.nav-item a{
  color: #f9f9f9;

}
.dropdown-menu a{
  color: #2863A4;
}
.heading{
  font-size: 3rem;
  font-style: initial;
  color: #f9f9f9;
}
.col-lg-6 .headingtext1{
  font-size: 1.1rem;
  font-style: oblique;
 
}



.rounded-circle{
  border-radius: 40px; 
  width: 400px; 
  
}
.card .icon{
  font-size: 3rem;
  text-align: center1
}
.card-title{
  text-align: center; 
}
.card-text{
  text-align: center; 
}
.card{
  padding: 0.5rem;
  background-color: rgb(108, 108, 44);
}
section{
  padding-top: 1rem;
  padding-bottom: 2rem;
}
.project-note{
  font-size: large;
  font-weight: bold;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #c1ca13;
}
.text p{
  text-align: center;
  
}
.text h1{
  text-align: center;
}
.head{
  font-family: "Single Day", cursive;
  font-weight: 400;
  font-style: normal;
}
.text-center .head .paragraph{
  font-family: "Jersey 25 Charted", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.body-banner p{
  color: blue;
  
}

.Bottom{
  background-image: linear-gradient(rgb(44, 107, 49), rgb(155, 155, 51));
  color: #f9f9f9; 
  padding-top: 100px;
  padding-bottom: 50px;
}
.footlogo img{
  display: flex;
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
}

.mainhall{
          display: flex;
          margin-left: 120px;
          height: 600px;
          width: 900px;
          float: left;
          border: 2px solid #0e0d0d;
}
.image {
  width: 340px;
  height: 340px;
  margin: 10px;
  border: 2px solid #242222;
  transition: transform 0.3s ease;
  cursor: pointer;
}
.firstimg {
  width: 300px;
  height: 250px;
  object-fit: cover;
}
.secondimg {
  width: 300px;
  height: 250px;
  object-fit: cover;
}
.col-lg-4 .setimg{
  width: 100px;
  height: 350px;
  object-fit: cover;
}

.card:hover{
  transform: scale(1.1);
}

.backgroundmilk{
  background-image: linear-gradient(to right, rgba(183, 176, 92, 0.879), rgb(244, 244, 153));
}
.heading1{
  font-size: 3rem;
  font-style: initial;
  color: #1b1919;
}
.headingtext1{
  font-size: 1rem;
  font-style: oblique;
  color: #121111;
}
.backgroundcheese{
  background-image: linear-gradient(to right, rgb(190, 186, 146), rgb(243, 243, 57));
}
.backgroundmozrela{
  background-image: linear-gradient(to right, rgb(245, 237, 142), rgb(250, 250, 217));
}
.backgroundbutter{
  background-image: linear-gradient(to right, rgb(115, 109, 47), rgb(214, 214, 99));
}
.backgroundghee{
  background-image: linear-gradient(to right, rgb(230, 225, 174), rgb(241, 241, 38));
}
.google-map {
  padding-bottom: 50%;
  position: relative;
}

.google-map iframe {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

/*** Button ***/
.btn {
  font-weight: 500;
  transition: .5s;
}

.btn.btn-primary {
  color: #FFFFFF;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: .5s;
}

.navbar .navbar-brand {
  height: 75px;
}

.navbar .navbar-nav .nav-link {
  margin-right: 30px;
  padding: 25px 0;
  color: var(--dark);
  font-weight: 500;
  outline: none;
}

.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: var(--primary);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}




@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link  {
      margin-right: 0;
      padding: 10px 0;
  }

  .navbar .navbar-nav {
      border-top: 1px solid #EEEEEE;
  }
}
.banner img{
  display: flex;
  flex-direction: column;
  background-size: contain;
}
.col-lg-3 .team-item .overflow-hidden img{
  background-size: cover;
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
      display: block;
      border: none;
      margin-top: 0;
      top: 150%;
      opacity: 0;
      visibility: hidden;
      transition: .5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
      top: 100%;
      visibility: visible;
      transition: .5s;
      opacity: 1;
  }
}

.navbar .btn:hover {
  color: #FFFFFF !important;
  background: var(--primary) !important;
}


@media (max-width: 768px) {

  .col-lg-3 .team-item .overflow-hidden img{
   
    width: 400px;
    height: 400px;
  }

}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, .08);
}

.team-item img {
  transition: .5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item .team-social {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(22, 22, 22, .7);
  opacity: 0;
  transition: .5s;
}

.team-item:hover .team-social {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
}

.team-item .team-social .btn {
  opacity: 0;
}

.team-item:hover .team-social .btn {
  opacity: 1;
}

/*** Project Portfolio ***/
#portfolio-flters li {
    display: inline-block;
    font-weight: 500;
    color: var(--dark);
    cursor: pointer;
    transition: .5s;
    border-bottom: 2px solid transparent;
}

#portfolio-flters li:hover,
#portfolio-flters li.active {
    color: var(--primary);
    border-color: var(--primary);
}

.portfolio-inner {
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.portfolio-inner img {
    transition: .5s;
}

.portfolio-inner:hover img {
    transform: scale(1.1);
}

.portfolio-inner .portfolio-text {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -50px;
    opacity: 0;
    transition: .5s;
}

.portfolio-inner:hover .portfolio-text {
    bottom: 0;
    opacity: 1;
}


/*** Quote ***/
@media (min-width: 992px) {
    .container.quote {
        max-width: 100% !important;
    }

    .quote-text  {
        padding-right: calc(((100% - 960px) / 2) + .75rem);
    }
}

@media (min-width: 1200px) {
    .quote-text  {
        padding-right: calc(((100% - 1140px) / 2) + .75rem);
    }
}

@media (min-width: 1400px) {
    .quote-text  {
        padding-right: calc(((100% - 1320px) / 2) + .75rem);
    }
}
@media only screen and (max-width: 600px) {
 h1{
  text-align: center;
 }
 h5{
  text-align: center;
 }
 p{
  text-align: center;
 }
 card{
  align-items: center;
 }
 .footericon{
  text-align: center;
 }
 .links{
  text-align: center;
 }
 .heading1{
  font-size: 2rem;
  font-style: initial;
  color: #1b1919;
}
.headingtext1{
  font-size: 1rem;
  font-style: oblique;
  color: #121111;
}
.firstimg{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.secondimg {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

}



@media (max-width: 991px) {
 h1{
  text-align: center;
 }
 h5{
  text-align: center;
 }
 p{
  text-align: center;
 }
 card{
  align-items: center;
 }
 .footericon{
  text-align: center;
 }
 .links{
  text-align: center;
 }
 .heading1{
  font-size: 2rem;
  font-style: initial;
  color: #1b1919;
}
.headingtext1{
  font-size: 1rem;
  font-style: oblique;
  color: #121111;
}
.firstimg{
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.secondimg {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
}
@media (max-width: 991px){
  .p-lg-5 h1{
    font-size: larger;
  }
  .ms-4 p{
    font-size: 10px;
  }
  .ms-4 h5{
    font-size: px;
  }
  
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
